import { createStore } from "vuex";
import { auth } from "./auth.module";

import { Store } from 'vuex'
import {InjectionKey} from "vue";

export interface UserLogin {
  username: string,
  password: string,
  email: string,
}

interface State {
  loggedIn: boolean,
  user: UserLogin
}


declare module '@vue/runtime-core' {
  // declare your own store states

  // provide typings for `this.$store`
  interface ComponentCustomProperties {
    $store: Store<State>
  }
}
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  modules: {
    auth,
  },
});

