import axios from 'axios';
import {AnyObject} from "chart.js/types/basic";
import {UserLogin} from "@/store/auth.module";

const API_URL = 'http://localhost:8080/api/auth/';

class AuthService {

    userId() {
        const user = JSON.parse(localStorage.getItem('user')!!);
        return user?.id
    }
    hasUser() {
        return localStorage.getItem('user') != null
    }

    addUser(obj: any) {
        localStorage.setItem('user', JSON.stringify(obj));
    }

    login(user: UserLogin) {

        const userObject = {
            "id": 1,
            "username": "admin",
            "email": "piowes@icloud.com",
            "roles": ["ROLE_ADMIN"],
            "accessToken": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTY2NzY1NzM3NSwiZXhwIjoxNjY3NzQzNzc1fQ.f7EDBA2U2fwNKBnd-JqYdP4z7k2I6nAdDW0dnvK9R9rY0IIcLzm9yhBF2XB3yx767KYwC0E9amD2umJPXUwwvw",
            "tokenType": "Bearer"
        };
        localStorage.setItem('user', JSON.stringify(userObject));

        return new Promise<any>((resolve, reject) => {
            resolve(userObject)
        })
        // return axios
        //   .post(API_URL + 'signin', {
        //     username: user.username,
        //     password: user.password
        //   })
        //   .then(response => {
        //     if (response.data.accessToken) {
        //       localStorage.setItem('user', JSON.stringify(response.data));
        //     }
        //
        //     return response.data;
        //   });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user: UserLogin) {
        return axios.post(API_URL + 'signup', {
            username: user.username,
            email: user.email,
            password: user.password
        });
    }
}

export default new AuthService();
