import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user')!!);
const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {loggedIn: false}, user: null};

//still not used somehow
export interface State {
    loggedIn: boolean,
    user: object
}

export interface UserLogin {
    username: string,
    password: string,
    email: string,
}


// @ts-ignore
export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login(context: any, user: UserLogin) {

            return AuthService.login(user).then(
                user => {
                    context.commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    context.commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout(context: any) {
            AuthService.logout();
            context.commit('logout');
        },
    },
    mutations: {
        loginSuccess(state: any, user: UserLogin) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state: any) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state:any) {
            state.status.loggedIn = false;
            state.user = null;
        },

    }
};
