<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="topbarImage()"/>
      <span>Tłumaczomat</span>
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button"
            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <div class="locale-changer">
          <Dropdown v-model="$i18n.locale" :options="$i18n.availableLocales" />
        </div>
      </li>
      <li>
        <Menu ref="menu" :model="overlayMenuItems" :popup="true"/>
        <button class="p-link layout-topbar-button" @click="toggleMenu">
          <i class="pi pi-user"></i>
          <span>Profile</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage:  { name: 'polski', code: 'pl' }, //todo dispatch in
      countries: [
        { name: 'polski', code: 'pl' },
        { name: 'english', code: 'en' }
      ],
      overlayMenuItems: [
        {
          label: 'Profil',
          icon: 'pi pi-user',
          to: '/profile',
        },
        {
          separator: true
        },
        {
          label: 'Wyloguj',
          icon: 'pi pi-home',
          command: () => {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        },
      ],
    }
  },
  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event);
    },
    topbarImage() {
      return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    }
  }
}
</script>