import axios from 'axios';
import authHeader from './auth-header';
import AuthService from "@/services/auth.service";

//TODO wazne zmienne srodowiskowe
// const API_URL = 'http://localhost:8080/api/';
const API_URL = 'https://admin.tlumaczenie.link/api/';
class UserService {

    getJWTToken(token: String) {
        return axios.get(API_URL + 'translator-token?token=' + token);
    }

    getOffer(offerId: String) {
        return axios.get(API_URL + 'translator-offer/' + offerId, {headers: authHeader()});
    }

    saveOffer(offerId: String, offer: any) {
        console.log("Saving offer", offer)
        return axios.post(API_URL + 'translator-offer/' + offerId, offer, {headers: authHeader()})
    }

    deleteOffer(offerId: String, offer: String) {
        console.log("Saving offer", offer)
        return axios.post(API_URL + 'translator-offer/' + offerId + "/delete/" + offer, {}, {headers: authHeader()})
    }
   confirmOffer(offerId: String) {
        console.log("Confirming offer", offerId)
        return axios.post(API_URL + 'translator-offer/' + offerId + "/confirm", {}, {headers: authHeader()})
    }


    getProfile() {
        const userId = AuthService.userId();
        console.log("findAllTasks ",userId)
        return axios.get(API_URL + 'translator/' + userId , {headers: authHeader()})
    }

    findAllTasks() {
        const userId = AuthService.userId();
        console.log("findAllTasks ",userId)
        return axios.get(API_URL + 'translator/' + userId + "/tasks", {headers: authHeader()})
    }
}

export default new UserService();
