export default function authHeader() {
    const item = localStorage.getItem('user');
    const user = JSON.parse(item!!);

    if (user && user.accessToken) {
        return {Authorization: 'Bearer ' + user.accessToken}; // for Spring Boot back-end
        // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    } else {
        return {};
    }
}
